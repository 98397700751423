import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Components
import SectionTitle from "../../components/common/section-title";

//Import Images
import img1 from "../../assets/images/features/mockup1.png";
import img2 from "../../assets/images/features/mockup2.png";

import SFN1 from "../../assets/images/features/SFN1.png";
import SFN2 from "../../assets/images/features/SFN2.png";
import SFN3 from "../../assets/images/features/SFN3.png";
import SFN4 from "../../assets/images/features/SFN4.png";

import FeatureBox from "../common/feature-box";

class Features extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="section" id="features">
          <Container>
            <SectionTitle title1="Our" title2="Features" />
            <Row className="mt-4 pt-4 vertical-content">
              <Col lg="6" className="mt-2">
                <div>
                  <img
                    src={SFN1}
                    alt=""
                    className="img-fluid mx-auto d-block"
                  />
                </div>
              </Col>
              <Col lg="6" className="mt-2 text-center">
                <FeatureBox
                  class=""
                  title="Stay organised with different calendar views: by day, week or month"
                  desc=""
                  link="#"
                />
              </Col>
            </Row>
            <Row className="mt-5 vertical-content">
              <Col lg="6" className="mt-2">
                <FeatureBox
                  class="text-center"
                  title="Schedule your appointments and time with ease"
                  desc=""
                  link="#"
                />
              </Col>
              <Col lg="6" className="mt-2">
                <div>
                  <img
                    src={SFN2}
                    alt=""
                    className="img-fluid mx-auto d-block"
                  />
                </div>
              </Col>
            </Row>
             <Row className="mt-4 pt-4 vertical-content">
              <Col lg="6" className="mt-2">
                <div>
                  <img
                    src={SFN3}
                    alt=""
                    className="img-fluid mx-auto d-block"
                  />
                </div>
              </Col>
              <Col lg="6" className="mt-2 text-center">
                <FeatureBox
                  class=""
                  title="Easy to manage your client information and session notes"
                  desc=""
                  link="#"
                />
              </Col>
            </Row>
            
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Features;
