import React, { Component } from 'react';
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Pricing
import PricingBox from './pricing-box';

class Pricing extends Component {
    state = {
        pricings : [
            { id : 1, title : "Monthly", price : "39", duration : "m",
                features :
                    { bandwith : "Yes", onlinespace : "Yes", support : "Yes", domain : "Free appointment reminders", hiddenFees : "-" }

            },
            { id : 2, title : "Annually", price : "397", duration : "y",
                features :
                    { bandwith : "Yes", onlinespace : "Yes", support : "Yes", domain : "Free appointment reminders", hiddenFees : "Online payments" }

            },
            { id : 3, title : "Lifetime", price : "1497", duration : "-",
                features :
                    { bandwith : "Yes", onlinespace : "Yes", support : "Yes", domain : "Free appointment reminders", hiddenFees : "Online payments" }

            },
        ]
    }
    render() {
        return (
            <React.Fragment>
                <section className="section bg-light" id="pricing">
                    <Container>
                    <SectionTitle title1="Our" title2="Pricing" />
                        <Row className="mt-4 pt-4">
                            <PricingBox pricings={this.state.pricings} />
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default Pricing;
