import React, { Component } from "react";
import { Container, Row, Col, FormGroup, Label } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Link } from "react-router-dom";
import { Animated } from "react-animated-css";

//Import Section Title
import SectionTitle from "../common/section-title";

class GetInTouch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: "",
      email: "",
      subject: "",
      comments: "",
      msgSendSuccess: false,
    };
  }

  handleSubmit = () => {
    let emailPattern = new RegExp(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/);

    if (
      this.state.firstname !== "" &&
      this.state.email !== "" &&
      emailPattern.test(this.state.email) &&
      this.state.subject !== "" &&
      this.state.comments !== ""
    ) {

const axios = require('axios');

    axios.post('https://apis.solutionfocusednotes.com/api/feedback/contact', {
    mail: 1,
    message: this.state.comments,
    from: this.state.email,
    to: 'admin@solutionfocusednotes.com',
    from_name:this.state.firstname,
    subject:this.state.subject,

  },
  {
   mode: 'no-cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
      withCredentials: true,
      credentials: 'same-origin',
  }
  )
  .then(function (response) {
    console.log(response);
  })
  .catch(function (error) {
    console.log(error);
  });
      this.setState({ msgSendSuccess: true });
      this.myFormRef.reset();

      setTimeout(() => {
        this.setState({ msgSendSuccess: false });
      }, 5000);
    }
  };

  onInputChangeHandlar = (event) => {
    const value = event.target.value;
    const name = event.target.name;

    this.setState({ [name]: value });
  };

  render() {
    return (
      <React.Fragment>
        <section className="section " id="contact">
          <Container>
            <SectionTitle title1="Get In" title2="Touch" />
            <div className="custom-form mt-4 pt-4">
              <div id="message">
                {this.state.msgSendSuccess ? (
                  <Animated
                    animationIn="bounceIn"
                    animationOut="flash"
                    animationInDuration={1000}
                    animationOutDuration={1000}
                    isVisible={true}
                  >
                    <fieldset>
                      <div id="success_page">
                        <h3>Email Sent Successfully.</h3>
                        <p>
                          Thank you <strong>{this.state.firstname}</strong>,
                          your message has been submitted to us.
                        </p>
                      </div>
                    </fieldset>
                  </Animated>
                ) : null}
              </div>
              <center>
              <a href="mailto:admin@solutionfocusednotes.com?subject=Solution Focused Notes Inquiry" target="_blank" className="btn btn-custom">Email Us</a>
              <div style={{marginTop:10}}> -- <strong>OR</strong> --</div>
              <div><i>Email Us</i> - <strong>admin@solutionfocusednotes.com</strong></div>
              </center>
            </div>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default GetInTouch;
