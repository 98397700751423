import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

class GetStarted extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="section"  style={{padding:30,background:'#fafaf9'}}>
          <Container>
            <Row className="vertical-content">
          <img
            src="https://thesfu.com/wp-content/uploads/2020/10/elliott_connie.jpg"
            alt="image"
            style={{width: 90,height: 90, borderRadius:'50%'}}
            className="img-fluid mx-auto d-block"
          />
          </Row>
            <Row>
              <Col md="12" className="text-black critarea-desc mt-3 mb-3">
              <q>For years I struggled to find an Electronic Health Records system that met the needs of a Solution Focused Professional. So I decided to create my own. Now, after years in development, I am excited to use the only EHR created specially for you!</q> - Elliott Connie
              </Col>

            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default GetStarted;
