import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
//Import Footer link
import FooterLinks from "./footer-links";

function terms() {
  window.open('https://app.solutionfocusednotes.com');
}
class Footer extends Component {
    state = {
        footerItems : [
           /* { title : "about Us" ,
                links : [
                    { linkTitle : "About Us", link : "#" },

                    { linkTitle : "Terms", link : "#" },
                    { linkTitle : "Privacy", link : "#" },
                ]
            },
            { title : "resaurses" ,
                links : [
                    { linkTitle : "Blog", link : "#" },
                    { linkTitle : "Help Center", link : "#" },
                    { linkTitle : "Video Terms", link : "#" },

                ]
            },
            { title : "more info" ,
                links : [


                ]
            },*/
        ]
    }
    render() {
        return (
            <React.Fragment>
        <section className="section bg-light">
            <Container>
                <Row>
                <Col md="3">
                  <ul className="list-unstyled company-sub-menu">
                <li><a target="_blank" href="https://app.solutionfocusednotes.com/terms" >Terms of Service</a></li>
                <li><a target="_blank" href="https://app.solutionfocusednotes.com/privacy" >Privacy Policy</a></li>
                </ul>
                </Col>
                    <Col lg="3" style={{display: this.state.showStore ? 'block' : 'none' }}>
                        <p className="text-uppercase footer-title">Help center</p>
                        <ul className="list-unstyled company-sub-menu">
                            <li><Link to="#">Support </Link></li>
                            <li><Link to="#">Live Support</Link></li>
                            <li><Link to="#">General Question</Link></li>
                        </ul>
                    </Col>
                    {
                        this.state.footerItems.map((item, key) =>
                    <Col lg="2" key={key}>
                        <p className="text-uppercase footer-title">{item.title}</p>
                        <ul className="list-unstyled company-sub-menu">
                        {
                            item.links.map((fLink, key) =>
                                <li key={key}><Link to={fLink.link}>{fLink.linkTitle}</Link></li>
                            )
                        }
                        </ul>
                    </Col>
                        )
                    }

                    <Col lg="3" style={{display: this.state.showStore ? 'block' : 'none' }}>
                        <p className="text-uppercase footer-title">Contact Us</p>
                        <div className="contact-us">
                            <p>Phone: +1 xxxxxxxxxx</p>
                            <p>Email: info@solutionfocusednotes.com</p>
                            <p>support@solutionfocusednotes.com</p>
                        </div>
                        <ul style={{display: this.state.showStore ? 'block' : 'none' }} className="footer-icons list-inline mt-4">
                            <li className="list-inline-item"><Link to="#" className=""><i className="mdi mdi-facebook"></i></Link></li>
                            <li className="list-inline-item"><Link to="#" className=""><i className="mdi mdi-twitter"></i></Link></li>
                            <li className="list-inline-item"><Link to="#" className=""><i className="mdi mdi-google-plus"></i></Link></li>
                            <li className="list-inline-item"><Link to="#" className=""><i className="mdi mdi-apple"></i></Link></li>
                        </ul>

                    </Col>
                </Row>
            </Container>
        </section>
                {/* Render footer links */}
                <FooterLinks/>
            </React.Fragment>
        );
    }
}

export default Footer;
