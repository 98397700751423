import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

//Importing Modal
import ModalSection from '../../components/common/ModalSection';

class Section extends Component {
    constructor() {
        super();
        this.state = {
            isOpen: false
        }
        this.callModal.bind(this)
    }

    callModal = () => {
        this.refs.child.openModal();
    }

    render() {
        return ( <
            React.Fragment >
            <
            section className = "bg-home home-height-half"
            id = "home" >
            <
            div className = "bg-overlay" > < /div> <
            div className = "home-center" >
            <
            div className = "home-desc-center" >
            <
            Container >
            <
            Row className = "row justify-content-center" >
            <
            Col lg = "10"
            className = "text-center" >
            <
            h6 className = "home-title text-white" > Client Note Taking for the Solution Focused Therapist < /h6> <
            h6 className = "home-sub-title text-white" > </h6>

            <p className = "pt-4 home-sub-title text-white mx-auto" > < /p> <
            div className = "watch-video pt-4" > {
                /*<Link
                                                        onClick={this.callModal}
                                                        to="#" className="video-play-icon text-white">
                                                        <i className="mdi mdi-play play-icon-circle play play-iconbar"></i> <span>Watch The Video!</span>
                                                    </Link>*/
            } <
            a href="https://app.solutionfocusednotes.com/auth/register" target="_blank"
            className = "btn btn-outline-custom" >
            Sign up here <
            /a> < /
            div > <
            /Col> < /
            Row > { /* Render ModalSection Component for Modal */ } <
            ModalSection ref = "child"
            channel = 'vimeo'
            videoId = '99025203' / >
            <
            /Container> < /
            div > <
            /div> < /
            section > <
            /React.Fragment>
        );
    }
}

export default Section;
