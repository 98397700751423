import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Components
import SectionTitle from "../common/section-title";

//Import Images
import img1 from "../../assets/images/features/AWS.png";
import img2 from "../../assets/images/features/hippa.png";
import FeatureBox from "../common/feature-box";

class Features extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="section" id="security">
          <Container>
            <SectionTitle title1="Security &" title2="Privacy" />
            <Row className="mt-4 pt-4 vertical-content">
              <Col lg="6" className="mt-2">
                <div>
                  <img
                    src={img2}
                    alt=""
                    className="img-fluid mx-auto d-block"
                  />
                </div>
              </Col>
              <Col lg="6" className="mt-2 text-center">
                <FeatureBox
                  class=""
                  title="Security and Privacy Program"
                  desc="Our Security and Privacy Program incorporates all aspects of the platform: people, process, and technology. It is based on HIPAA, HITRUST, NIST-CSF, PCI, ISO 27001/2, and CCPA frameworks."
                  link="#"
                />
              </Col>
            </Row>
            <Row className="mt-5 vertical-content">
              <Col lg="6" className="mt-2">
                <FeatureBox
                  class="text-center"
                  title="Secure servers"
                  desc="Physical security is an important component to protect your data. Our platform servers are housed in a facility protected by proximity readers, biometric scanners, and security guards 24/7, 365 days a year."
                  link="#"
                />
              </Col>
              <Col lg="6" className="mt-2">
                <div>
                  <img
                    src={img1}
                    alt=""
                    className="img-fluid mx-auto d-block"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Features;
