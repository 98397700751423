import React, { Component } from 'react';
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Service Box
import ServiceBox from "./service-box";

class Services extends Component {
    state = {
        services : [
            { title : "Calendar", icon : "mbri-code", description : "Schedule your client appointments and other business that you need to keep track of." },
            { title : "Treatment Plans", icon : "mbri-features", description : "made easy, specifically designed for those using the Solution Focused Approach. Ability to download and print." },
            { title : "Progress Notes", icon : "mbri-growing-chart", description : "Feel confident writing your notes in a Solution Focused way. Ability to download and print." },
        ],
        services1 : [
            { title : "Upload Documents", icon : "mbri-photos", description : "Store your clients intake documentation, additional notes and images." },
            { title : "Text Reminders", icon : "mbri-responsive", description : "Keep your ‘no show’ rates down with our free text reminder." },
            { title : "Security", icon : "mbri-download", description : "SSL enabled servers and double layer encryption makes your data secure at safe." },
        ],
        
    }
    render() {
        return (
            <React.Fragment>
        <section id="service" className="section">
            <Container>
            <SectionTitle title1="Our" title2="Services" />
                <Row className="pt-4 mt-4">
                <ServiceBox services={this.state.services} />
                </Row>
                <Row>
                <ServiceBox services={this.state.services1} />
                </Row>
            </Container>
        </section>
            </React.Fragment>
        );
    }
}

export default Services;